import { getDashboard } from "../../../services/client.services"
import { mapGetters } from 'vuex'
import { duplicateObject, objEmpty } from '../../../helpers/helpers'
import { Clientscharts } from "../../../factories/staticGraphicFactory"
import moment from 'moment'
export const DashboardMixin = {
    data() {
        return {
            dashboard: {},
            dashboardClean: {},
            updated: '',
            statsGraphic: {},
            period: 1,
            chartPeriod: 'daily',
            filt: {
                status: '',
                user: '',
                data: '',
                matriculaId: ''
            },
            fruits: 0,
            matriculas: [],
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            title: '',
            loading: true,

        };
    },
    created() {

        this.callMatriculas(1)
    },
    mounted() {},
    watch: {

    },
    computed: {
        ...mapGetters(['getClientname', 'getClientid']),
        emptyStats: function() {
            return objEmpty(this.statsGraphic)
        },
        emptyMatriculas: function() {
            if (this.matriculas.length === 0) return true
            else return false
        },
        emptyDashboard: function() {
            return objEmpty(this.dashboard)
        }
    },
    methods: {
        async callMatriculas(period) {
            this.period = period
            try {
                this.loading = true
                let initial = {
                    type: 'byPeriod',
                    period: this.period,
                    client_name: this.getClientname
                }
                const { data } = await getDashboard(initial)
                this.dashboard = duplicateObject(data.data)
                this.matriculas = duplicateObject(this.dashboard.matriculas)

                this.graphicMount(duplicateObject(this.dashboard.matriculas))
                this.loading = false
            } catch (error) {
                this.loading = false
                throw console.log(error)
            }
        },
        async getbyPeriod() {
            try {
                this.loading = true
                let initial = {
                    period: 'byDate',
                    startDate: moment(this.dateRange.startDate).format("L"),
                    endDate: moment(this.dateRange.endDate).format("L"),
                    client_name: this.getClientname
                }
                const { data } = await getDashboard(initial)
                this.dashboard = duplicateObject(data.data)
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.graphicMount(duplicateObject(this.dashboard.matriculas))
                this.loading = false
            } catch (error) {
                this.loading = false
                throw console.log(error)
            }
        },
        orderData() {

        },
        getFromcalendar() {
            this.getbyPeriod()

        },
        graphicMount(data = duplicateObject(this.dashboard.matriculas), period = 'daily') {
            this.statsGraphic = new Clientscharts(period)
            this.statsGraphic.labels = this.statsGraphic.mountLabels(data)
            this.statsGraphic.stats = this.statsGraphic.mountStats(data)
            this.statsGraphic.mountChart(this.statsGraphic.labels, this.statsGraphic.stats, data)
            this.statsGraphic.mountList(this.statsGraphic.labels, this.statsGraphic.stats, data)
        },
        cleanSearch() {
            this.filt.status = '',
                this.filt.user = '',
                this.filt.matriculaId = '',
                this.filt.data = ''
        },
        filterbyUser() {
            this.matriculas = this.matriculas.filter((val) => {
                if (this.filt.user.match(val.user)) {
                    return val
                }
            })
            if (this.matriculas.length === 0) {
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyStatus() {
            this.matriculas = this.matriculas.filter((val) => {
                if (parseInt(this.filt.status) === val.status) {
                    return val
                }
            })
            if (this.matriculas.length === 0) {
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyID() {
            this.matriculas = this.matriculas.filter((val) => {
                if (parseInt(this.filt.matriculaId) === val.id) {
                    return val
                }
            })
            if (this.matriculas.length === 0) {
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyDate() {
            this.matriculas = this.matriculas.filter((val) => {
                if (this.filt.data === val.data) {
                    return val
                }
            })
            if (this.matriculas.length === 0) {
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },




    }
};